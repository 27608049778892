import CaptionedImage from '../CaptionedImage.js'


import { Trans, useTranslation } from 'react-i18next'


export default function Lounge(){
	const {t, i18n} = useTranslation("lounge");

	return (
		<div>
			<h4>{t('title')}</h4>
			<br />
			<p>
				{t('c1')}
			</p>
			<p>
				{t('c2')}
			</p>
			<CaptionedImage src="/images/prototype.jpg" caption={t('cap1')} />
			<p>
				{t('c3')}
			</p>
			<p>
				{t('c4')}
			</p>
			<CaptionedImage src="/images/frame.jpg" caption={t('cap2')} />
			<p>
				{t('c5')}
			</p>
			<CaptionedImage src="/images/construction.jpg" caption={t('cap3')} />
			<p>
				{t('c6')}
			</p>
			<CaptionedImage src="/images/roof.jpg" caption={t('cap4')} />
			<CaptionedImage src="/images/backroom.jpg" caption={t('cap5')} />
			<p>
				{t('c7')}
			</p>
			<CaptionedImage src="/images/standingdesk.jpg" caption={t('cap6')} />
			<CaptionedImage src="/images/couchtable.jpg" caption={t('cap7')} />
			<CaptionedImage src="/images/bed.jpg" caption={t('cap8')} />
			<p>
				{t('c8')}
			</p>
			<CaptionedImage src="/images/tv.jpg" caption={t('cap9')} />
			<CaptionedImage src="/images/pc.jpg" caption={t('cap10')}  />

			<p>
				{t('c9')}
			</p>
			<CaptionedImage src="/images/digging.jpg" caption={t('cap11')} />
			<CaptionedImage src="/images/pc2.jpg" caption={t('cap12')} />
			<p>
				{t('c10')}
			</p>
			<p>
				{t('c11')}
			</p>
			<CaptionedImage src="/images/davidshed.jpg" caption={t('cap13')} />

		</div>
	)

}
