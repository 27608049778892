import CaptionedVideo from '../CaptionedVideo.js'
import CaptionedImage from '../CaptionedImage.js'
import {Link} from 'react-router-dom'

import { Trans, useTranslation } from 'react-i18next'


export default function HLMSDS(){
	const {t, i18n} = useTranslation("hlmsds");

	return (
		<div>
			<h4>{t('title')}</h4>
			<br />
			<p>
				{t('c1')}
			</p>
			<p>
				<Trans i18nKey="c3" t={t}>
					x
					<Link to="https://github.com/white-waluigi/HLMS_DS">y</Link>.
					z
				</Trans>
			</p>
			<CaptionedVideo src="/images/demo.webm" caption={t('cap1')}  />
			<p>
				
				{t('c2')}
			</p>
			<CaptionedImage src="/images/3by2.png" caption={t('cap2')} />
			<CaptionedImage src="/images/grime.png" caption={t('cap2.5')} />
			<CaptionedVideo src="/images/lights.webm" caption={t('cap3')} />
			<p>
				{t('c4')}
			</p>
			<CaptionedVideo src="/images/sharptransparency.webm" caption={t('cap4')} />
			<p>
				{t('c5')}
			</p>
			<CaptionedVideo src="/images/ditheringtransperancy.webm" caption={t('cap5')} />
			<p>
				{t('c6')}
			</p>
			<CaptionedVideo src="/images/refraction.webm" caption={t('cap6')} />
			<p>
				{t('c7')}
			</p>
			<CaptionedVideo src="/images/textureAnim.webm" caption={t('cap7')} />
			<p>
				{t('c8')}
			</p>
			<CaptionedVideo src="/images/terrain.webm" caption={t('cap8')} />
			<CaptionedImage src="/images/materialids.png" caption={t('cap8.5')} />
			<p>
				{t('c9')}
			</p>
			<CaptionedVideo src="/images/shadowMaps.webm" caption={t('cap9')} />
			<p>
				{t('c10')}
			</p>


			<CaptionedImage src="/images/nofilter.png" caption={t('cap10')} />
			<CaptionedImage src="/images/pcf.png" caption={t('cap11')} />
			<CaptionedImage src="/images/pcfsmooth.png" caption={t('cap12')} />

			<p>
				{t('c11')}
			</p>
			<CaptionedVideo src="/images/shadowCascade.webm" caption={t('cap13')} />
			<p>
				{t('c12')}
			</p>
			<CaptionedImage src="/images/earlyShadow.png" caption={t('cap14')} />
			<CaptionedImage src="/images/earlyScene.png" caption={t('cap15')} />
			<CaptionedImage src="/images/earlyShadowTest.png" caption={t('cap16')} />
			<CaptionedImage src="/images/earlyGlowTest.png" caption={t('cap17')} />
			<p>
				{t('c13')}
			</p>
			<CaptionedImage src="/images/testingProjector.png" caption={t('cap18')} />
			<p>
				{t('c14')}
			</p>

		</div>
				)

}


