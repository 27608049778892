import logo from './logo.svg';
import './App.css';
import Space from './space/Space.js'
import MainRouter from './content/MainRouter.js'
import {SiteContext} from './space/SiteContext.js'
import {useState} from 'react';
import "bootstrap-icons/font/bootstrap-icons.css";


// here goes my personal variables
import "./css/main.scss"



function App() {


	const [siteContext,setSiteContext] = useState({
		spaceEnabled: true,
	})


	return (
		<>
			<SiteContext.Provider value={{siteContext,setSiteContext}}>
				{siteContext.spaceEnabled?<Space />:null}
				<MainRouter />
			</SiteContext.Provider>
		</>
	);
}

export default App;
