import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import CaptionedImage from '../CaptionedImage'


export default function Tagflip(){
	const {t, i18n} = useTranslation("tagflip");

	// clicks posts tags "post shares" "tag shares" market inventory boosts

	return (
		<div>
			<h4>{t('title')}</h4>
			<br />
			<p>
				<Trans i18nKey="c1" t={t}>
					x
					<Link to="https://tagflip.marvinwyss.ch">y</Link>.
					z
				</Trans>
			</p>
			<h5>{t('s1')}</h5>
			<p>
				<ul>
					<li><b>{t('terms.clicks.name')}</b> : {t('terms.clicks.description')}</li>
					<li><b>{t('terms.post.name')}</b> : {t('terms.post.description')}</li>
					<li><b>{t('terms.tag.name')}</b> : {t('terms.tag.description')}</li>
					<li><b>{t('terms.postshare.name')}</b> : {t('terms.postshare.description')}</li>
					<li><b>{t('terms.tagshare.name')}</b> : {t('terms.tagshare.description')}</li>
					<li><b>{t('terms.market.name')}</b> : {t('terms.market.description')}</li>
					<li><b>{t('terms.inventory.name')}</b> : {t('terms.inventory.description')}</li>
					<li><b>{t('terms.boost.name')}</b> : {t('terms.boost.description')}</li>

				</ul>
			</p>


			<p>
				{t('c2')}
			</p>
				<CaptionedImage src="/images/tfpost.png" caption={t('capposts')} />
			<p>
				{t('c3')}
			</p>
				<CaptionedImage src="/images/tfbuytag.png" caption={t('captags')} />
			<p>
				{t('c4')}
			</p>
				<CaptionedImage src="/images/tfprofile.png" caption={t('capprofile')} />
			<p>
				{t('c5')}
			</p>
				<CaptionedImage src="/images/tfstats.png" caption={t('capstats')} />
			<p>
				{t('c7')}
			</p>
			<p>
				{t('c6')}
			</p>
			<p>
				{t('c8')}
			</p>
			<p>
				{t('c9')}
			</p>
		</div>
	)

}


