import {Link} from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next'

export default function ISO(){
	const {t, i18n} = useTranslation("iso");

	return (
		<div>
			<h4>{t('title')}</h4>
			<br/>
			<p>
				<Trans i18nKey="c1" t={t}>
					x<Link to="/workhistory/csb">y</Link>z
				</Trans>
			</p>
			<p>
				{t('c2')}
			</p>
			<p>
				{t('c3')}
			</p>
		</div>
	)

}


