//load vertices and indices from obj file
export function  loadObj(obj){

	let data =obj.split("\n")

	//filter out not starting with v or l
	let vertices=data.filter(line=>line.startsWith("v ")).map(line=>line.split(" ").slice(1).map(x=>parseFloat(x)));
	let indices=data.filter(line=>line.startsWith("l ")).map(line=>line.split(" ").slice(1).map(x=>parseInt(x)-1));


	//now convert lines and indices to arrays of the form [n][2]
	let lines=[];

	for(let i of indices){
		let line=[]

		//ignore add x and z
		line.push([vertices[i[0]][0],vertices[i[0]][2]]);
		line.push([vertices[i[1]][0],vertices[i[1]][2]]);

		lines.push(line);
	}

	return lines;

}

//convert model to points
export function makeSamples(lines,numSamples,width,height){
	let lengths=[];
	let totalLength=0;
	for(let l of lines){
		let length=Math.sqrt((l[0][0]-l[1][0])**2+(l[0][1]-l[1][1])**2);
		lengths.push(length);
		totalLength+=length;
	}

	lengths=lengths.map(x=>x/totalLength);


	let samples=[];
	let sum=0;
	let j=0;

	for(let i=0;i<numSamples;i++){
		let x=i/numSamples;
		while(sum<=x){
			sum+=lengths[j];
			j++;
		}
		let t=(x-sum+lengths[j-1])/lengths[j-1];
		let line=lines[j-1];
		let x1=line[0][0];
		let y1=line[0][1];
		let x2=line[1][0];
		let y2=line[1][1];

		let pLine=[x1*(1-t)+x2*t,y1*(1-t)+y2*t]
		pLine[0]=pLine[0]*Math.min(width,height)/6+width/2;
		pLine[1]=pLine[1]*-Math.min(width,height)/6+height/2;
		samples.push(pLine);
	}

	return samples;



}


