import {Link} from 'react-router-dom'
import CaptionedImage from '../CaptionedImage.js'

import { Trans, useTranslation } from 'react-i18next'


export default function Website(){
	const {t, i18n} = useTranslation("website");

	return (
		<div>
			<h4>{t('title')}</h4>
			<br />
			<p>
				{t('c1')}
			</p>
			<p>
				{t('c3')}
			</p>
			<p>
				{t('c4')}
			</p>
			<p>
				<Trans i18nKey="c2" t={t}>
					x<Link to="/stars">y</Link>z
				</Trans>
			</p>
			<p>
				{t('c5')}
			</p>
			<p>
				{t('c6')}
			</p>
			<CaptionedImage src="/images/tux.png" caption={t('cap1')}/>

		</div>
	)

}


