import {useTranslation,Trans} from 'react-i18next'



export default function BBZ(){
	const {t} = useTranslation("bbz");

	return (
		<div>
			<h4>{t('title')}</h4>
			<br/>
			<p>
				{t('c1')}
			</p>
			<p>
				{t('c2')}
			</p>
			<p>
				{t('c3')}
			</p>
		</div>
	)

}


