import { Trans, useTranslation } from 'react-i18next'
import  CaptionedImage  from '../CaptionedImage'


export default function Compression(){
	const {t, i18n} = useTranslation("compression");

	return (
		<div>
			<h4>{t('title')}</h4>
			<br />
			<p>
				<Trans i18nKey="c1" t={t}>
					x
					<a href="/downloads/compression.zip">y</a>
					z
				</Trans>
			</p>
			<CaptionedImage src="/images/editing.png" caption={t('cap1')} />
			<CaptionedImage src="/images/compression.png" caption={t('cap2')} />
		</div>
	)

}


