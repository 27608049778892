import CaptionedImage from '../CaptionedImage.js'

import { Trans, useTranslation } from 'react-i18next'
export default function Lotto(){
	const {t, i18n} = useTranslation("lotto");
	return (
		<div>
			<h4>{t('title')}</h4>
			<br />
			<p>
				{ t('c1') }
			</p>
			<p>
				{ t('c2') }
			</p>

			<p>
				{ t('c3') }
			</p>

			<p>
				{ t('c5') }
			</p>
			<p>
				{ t('c4') }
			</p>
			<CaptionedImage src={"/images/lotto.jpg"} caption={t('cap1')}/>
			<p>
				{ t('c6') }
			</p>
		</div>
	)

}

