import {useContext,useEffect, useState} from 'react';
import {LightBoxContext} from './LightBoxContext.js'

export default function LBVideo({src,width,height,maxHeight,className}) {


	const fileWithoutFolder=src.split("/").pop();
	return (
		<div className={"LBVideo "+className}>
			<video 
				className="mx-auto m-2" 
				src={src} 
				width={width} 
				height={height} 
				style={{maxHeight,maxWidth:width?"":"100%"}} 
				controls
				preload="none" 
				autoPlay={false} 
				muted={false} 
				loop={false}
				poster={"/images/thumbs/"+fileWithoutFolder+"_thumb.png"}
			/>
		</div>
	);

}

