import CaptionedImage from '../CaptionedImage.js'
import { Trans, useTranslation } from 'react-i18next'


export default function Reception(){
	const {t, i18n} = useTranslation("reception");
	return (
		<div>
			<h4>{t('title')}</h4>
			<br />
			<p>
				{t('c1')}
			</p>
			<p>
				{t('c2')}
			</p>
			<p>
				{t('c3')}
			</p>

			<CaptionedImage src={"/images/reception.jpg"} caption={t('cap1')}/>

			<p>
				{t('c4')}
			</p>
			<p>
				{t('c5')}
			</p>
		</div>
	)

}


