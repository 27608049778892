import {Link} from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'



export default function Trikora(){
	const {t, i18n} = useTranslation("trikora");

	return (
		<div>
			<h4>{t('title')}</h4>
			<br/>
			<p>
				{t('c1')}
			</p>
			<p>
				<Trans i18nKey={"c2"} t={t}>
					x<Link to="https://chatbot.marvinwyss.ch">y</Link>z
					<Link to="/projects/chatbot">y</Link>z
				</Trans>
			</p>
		</div>
	)

}



