import {  ScrollRestoration,useNavigate,useLocation,Routes,Outlet, Navigate, Route, BrowserRouter, NavLink  } from "react-router-dom";
import Navbar from "./Navbar.js"
import GenericContainer from "./GenericContainer.js"
import AboutMe from "./AboutMe.js"
import Projects from "./Projects.js"
import WorkHistory from "./WorkHistory.js"
import Education from "./Education.js"
import { useTransition, animated } from 'react-spring';
import styles from './Router.module.css'; 
import { useContext ,useEffect} from 'react';
import Article from './Article.js'
import ReactGA from "react-ga4";


import {SiteContext} from '../space/SiteContext.js';


//articles
import Allware from './articles/Allware.js'
import CSB from './articles/CSB.js'
import Adiacom from './articles/Adiacom.js'
import Trikora from './articles/Trikora.jsx'

//education
import NLP from './articles/NLP.js'
import Deepl from './articles/Deepl.js'
import FHNW from './articles/FHNW.js'
import BBZ from './articles/BBZ.js'
import ISO from './articles/ISO.js'
import Kanti from './articles/Kanti.js'
import Kreis from './articles/Kreis.js'
import PM from './articles/PM.js'

//projects
import Lounge from './articles/Lounge.js'
import Faszination from './articles/Faszination.js'
import Spesen from './articles/Spesen.js'
import Reception from './articles/Reception.js'
import HLMSDS from './articles/HLMSDS.js'
import Icarus from './articles/Icarus.js'
import Tierheim from './articles/Tierheim.js'
import Compiler from './articles/Compiler.js'
import Killroy from './articles/Killroy.js'
import Raytracer from './articles/Raytracer.js'
import Lotto from './articles/Lotto.js'
import Personenstau from './articles/Personenstau.js'
import DR from './articles/DR.js'
import Alarm from './articles/Alarm.js'
import Compression from './articles/Compression.js'
import Website from './articles/Website.js'
import Tagflip from './articles/Tagflip.js'
import Vector from './articles/Vector.js'
import Jobquery from './articles/Jobquery.js'
import Chatbot from './articles/Chatbot.js'

import {useTranslation} from 'react-i18next';

let renders=0
export default function MainRouter({}) {
	console.log("rerender")

	const {t,i18n} = useTranslation("ui");


	const selectedLanguage = i18n.language.startsWith("de")?"de":"en";


	const {setSiteContext,siteContext} = useContext(SiteContext);
	let loca = useLocation();




	//handle google analytics
	useEffect(() => {
		ReactGA.initialize("G-H87MZNNWGJ");
	}, []);
	
	useEffect(() => {
		ReactGA.send({ 
			hitType: "pageview", 
			page: loca.pathname+loca.search, 
			title: loca.pathname+" space:"+siteContext.spaceEnabled+" lang:"+selectedLanguage
		});
	}, [loca.pathname]);

	//check if loca matches /stars/*
	const stars= loca.pathname.match(/\/stars/)


	const transitions = useTransition(loca.pathname, 
		{
			from: { transform: "translate(20vw)",opacity:0   },
			enter: {transform:  "translate(0vw)" ,opacity:1 },
			leave: { transform:  "translate(20vw)",  opacity:0 },
			//from: { opacity:0  },
			//enter: {opacity:1},
			//leave: {opacity:0   },
			config: { duration: 300 }
		});

	return (
		stars?
			null
			:
		<div className="d-flex flex-column h-100">
			<div>
				<Navbar />
			</div>
			<div className="flex-grow-1 position-relative">
				{transitions((style,item,key) => (
					<div  className={styles.overlap}>

						<animated.div className="h-100 d-flex"	 key={key} style={style}>
							<Routes location={item}>
								<Route path="/" element={<><Outlet /></>}>
									{/* Articles />*/}

									<Route path="workhistory/allware" element={
										<Article back="/workhistory">
											<Allware/>
										</Article>
										} />
									<Route path="workhistory/csb" element={
										<Article back="/workhistory">
											<CSB />
										</Article>
										} />
									<Route path="workhistory/adiacom" element={
										<Article back="/workhistory">
											<Adiacom />
										</Article>
										} />
									<Route path="workhistory/trikora" element={
										<Article back="/workhistory">
											<Trikora/>
										</Article>
										} />

									<Route path="education/nlp" element={
										<Article back="/education">
											<NLP />
										</Article>
										} />
									<Route path="education/deepl" element={
										<Article back="/education">
											<Deepl />
										</Article>
										} />
									<Route path="education/fhnw" element={
										<Article back="/education">
											<FHNW />
										</Article>
										} />
									<Route path="education/bbz" element={
										<Article back="/education">
											<BBZ />
										</Article>
										} />
									<Route path="education/iso" element={
										<Article back="/education">
											<ISO />
										</Article>
										} />
									<Route path="education/kanti" element={
										<Article back="/education">
											<Kanti />
										</Article>
										} />
									<Route path="education/kreis" element={
										<Article back="/education">
											<Kreis/>
										</Article>
										} />
									<Route path="education/pm" element={
										<Article back="/education">
											<PM />
										</Article>
										} />

									<Route path="projects/lounge" element={
										<Article back="/projects">
											<Lounge />
										</Article>
										} />
									<Route path="projects/faszination" element={
										<Article back="/projects">
											<Faszination />
										</Article>
										} />
									<Route path="projects/spesen" element={
										<Article back="/projects">
											<Spesen />
										</Article>
										} />
									<Route path="projects/reception" element={
										<Article back="/projects">
											<Reception />
										</Article>
										} />
									<Route path="projects/hlmsds" element={
										<Article back="/projects">
											<HLMSDS />
										</Article>
										} />
									<Route path="projects/icarus" element={
										<Article back="/projects">
											<Icarus />
										</Article>
										} />
									<Route path="projects/tierheim" element={
										<Article back="/projects">
											<Tierheim />
										</Article>
										} />
									<Route path="projects/compiler" element={
										<Article back="/projects">
											<Compiler/>
										</Article>
										} />
									<Route path="projects/killroy" element={
										<Article back="/projects">
											<Killroy />
										</Article>
										} />
									<Route path="projects/raytracer" element={
										<Article back="/projects">
											<Raytracer />
										</Article>
										} />
									<Route path="projects/Lotto" element={
										<Article back="/projects">
											<Lotto />
										</Article>
										} />
									<Route path="projects/personenstau" element={
										<Article back="/projects">
											<Personenstau />
										</Article>} />
									<Route path="projects/dr" element={
										<Article back="/projects">
											<DR />
										</Article>
										} />
									<Route path="projects/alarm" element={
										<Article back="/projects">
											<Alarm />
										</Article>
										} />
									<Route path="projects/compression" element={
										<Article back="/projects">
											<Compression />
										</Article>
										} />
									<Route path="projects/website" element={
										<Article back="/projects">
											<Website />
										</Article>
										} />
									<Route path="projects/tagflip" element={
										<Article back="/projects">
											<Tagflip />
										</Article>
										} />
									<Route path="projects/vector" element={
										<Article back="/projects">
											<Vector />
										</Article>
										} />
									<Route path="projects/jobquery" element={
										<Article back="/projects">
											<Jobquery/>
										</Article>
										} />
									<Route path="projects/chatbot" element={
										<Article back="/projects">
											<Chatbot/>
										</Article>
										} />




									{/* Pages />*/}

									{<Route path="" element={ <Navigate to="/aboutme" replace /> } />}
									<Route path="aboutme" element={<AboutMe />} />
									<Route path="workhistory" element={<WorkHistory/>} />
									<Route path="education" element={<Education/>} />
									<Route path="projects" element={<Projects/>} />
									<Route path="stars" element={null} />






									<Route path="*" element={"404"} />

								</Route>
							</Routes>
						</animated.div>
					</div>
				))}
			</div>
		</div>
		
	)

}









