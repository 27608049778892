import {useState, useEffect} from 'react';
import * as Bootstrap from 'react-bootstrap';
import {LightBoxContext} from './LightBoxContext.js';
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Video from "yet-another-react-lightbox/plugins/video";


import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

export default function GenericContainer({children,className,style}) {

	const [lightBoxImages, setLightBoxImages] = useState([]);
	const [lightBoxIndex, setLightBoxIndex] = useState(0);
	const [lightBoxOpen,setLightBoxOpen] = useState(false)

	const addLightBoxImage = (src) => {
		setLightBoxImages((prev) => [...prev, src]);
	};
	const removeLightBoxImage = (src) => {
		setLightBoxImages((prev) => prev.filter((item) => item.src !== src));
	};

	const openLightBox = (imageName) => {


		//find index with src==imageName
		const index = lightBoxImages.findIndex((item) => item.src === imageName);
		if(index>=0){
			setLightBoxIndex(index)
			setLightBoxOpen(true);
		}
	};



	return(
		<LightBoxContext.Provider value={{lightBoxImages, addLightBoxImage, removeLightBoxImage,openLightBox}}>
			<div className={className+" generic-container"} style={style}>
				<Bootstrap.Container className={"shadowed"}>
				{children}
				</Bootstrap.Container>
			</div>
			<Lightbox
				plugins={[Zoom]}
				open={lightBoxOpen}
				close={() => setLightBoxOpen(false)}
				slides={lightBoxImages}
				index={lightBoxIndex}
				controller={{
					closeOnBackdropClick: true,
				}}
			/>
		</LightBoxContext.Provider>
	)
}
