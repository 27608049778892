import CaptionedImage from '../CaptionedImage.js'
import CaptionedVideo from '../CaptionedVideo.js'
import {Link} from 'react-router-dom'

import { Trans, useTranslation } from 'react-i18next'


export default function Compiler(){
	const {t, i18n} = useTranslation("compiler");

	return (
		<div>
			<h4>IML Compiler</h4>

			<p>
				<Trans i18nKey="c1" t={t}>
					x<Link to="/education/fhnw">y</Link>z
				</Trans>
			</p>
			<p>{t('c2')}</p>
			<div>
				<Trans i18nKey="c3" t={t}>
					x
					<ul>
						<li>
							y
						</li>
						<li>
							z
						</li>
						<li>
							w	
						</li>
					</ul>
				</Trans>

			</div>
			<p>{t('c4')}</p>
			<p>{t('c5')}</p>
			<p>{t('c6')}</p>
			<p>{t('c7')}</p>
			<p>{t('c8')}</p>
			<CaptionedImage src={"/images/tree.png"} caption={t('cap1')}/>
			<p>{t('c9')}</p>


			<CaptionedVideo src={"/images/compiler.webm"} caption={t('cap2')}/>

		</div>
	)

}


