
const tux=`
# Blender 3.4.1
# www.blender.org
mtllib tux.mtl
o Plane
v -2.005056 0.000000 2.040323
v -2.028968 0.000000 1.866103
v -1.940150 0.000000 1.681636
v -1.994807 0.000000 1.493752
v -1.974311 0.000000 1.305868
v -1.697610 0.000000 1.182890
v -1.485814 0.000000 0.916437
v -1.253521 0.000000 0.895941
v -1.113462 0.000000 1.022335
v -0.501987 0.000000 1.948089
v -0.542979 0.000000 2.221374
v -0.717199 0.000000 2.358017
v -1.021229 0.000000 2.354601
v -1.441405 0.000000 2.180381
v -1.878661 0.000000 2.129140
v -0.402921 0.000000 1.927592
v -0.037402 0.000000 1.985665
v 0.300789 0.000000 1.920760
v 0.625315 0.000000 1.739709
v 0.553578 0.000000 2.084731
v 0.628731 0.000000 2.293111
v 0.867856 0.000000 2.440002
v 1.127477 0.000000 2.368265
v 1.438339 0.000000 2.094979
v 1.971245 0.000000 1.780701
v 2.008821 0.000000 1.640643
v 1.817522 0.000000 1.381022
v 1.732120 0.000000 1.083824
v 1.732120 0.000000 1.083824
v 1.646719 0.000000 1.012087
v 1.499828 0.000000 0.991590
v 1.305112 0.000000 1.135065
v 1.130893 0.000000 1.165809
v 1.007914 0.000000 1.131649
v 0.929345 0.000000 1.015503
v 0.905432 0.000000 0.868612
v 0.802950 0.000000 0.940349
v 0.714132 0.000000 1.104320
v 0.690220 0.000000 1.449343
v 1.516908 0.000000 0.813955
v 1.257287 0.000000 0.646568
v 1.089900 0.000000 0.615823
v 0.977170 0.000000 0.619239
v 1.182133 0.000000 0.561166
v 1.209462 0.000000 0.328873
v 1.161637 0.000000 0.117077
v 1.024994 0.000000 -0.142544
v 0.919096 0.000000 -0.272354
v 1.674047 0.000000 0.605575
v 1.667215 0.000000 0.397195
v 1.592061 0.000000 0.137574
v 1.366601 0.000000 -0.207449
v 1.076236 0.000000 -0.610545
v 0.826863 0.000000 -1.023889
v 0.816615 0.000000 -1.956475
v 0.707300 0.000000 -2.199015
v 0.604818 0.000000 -2.335658
v 0.396438 0.000000 -2.451804
v 0.276876 0.000000 -2.489381
v -0.194541 0.000000 -2.482549
v -0.447330 0.000000 -2.366402
v -0.583972 0.000000 -2.192183
v -0.679622 0.000000 -1.966723
v -0.689870 0.000000 -1.642196
v -0.683038 0.000000 -1.105874
v -0.724031 0.000000 -0.924823
v -0.799184 0.000000 -0.750603
v -0.918747 0.000000 -0.542223
v -1.195448 0.000000 -0.166456
v -1.465317 0.000000 0.400611
v -1.513142 0.000000 0.571414
v -1.502894 0.000000 0.848115
v -0.700238 0.000000 1.652469
v -0.678272 0.000000 1.605792
v -0.591783 0.000000 1.605792
v -0.506382 0.000000 1.540318
v -0.503535 0.000000 1.426449
v -0.583243 0.000000 1.284112
v -0.697112 0.000000 1.158857
v -1.033025 0.000000 0.865644
v -1.186748 0.000000 0.629366
v -1.178208 0.000000 0.299147
v -0.936237 0.000000 -0.196183
v -1.033025 0.000000 0.751775
v -1.010251 0.000000 0.489877
v -0.910616 0.000000 0.188125
v -0.625944 0.000000 -0.480855
v -0.574703 0.000000 -0.680126
v -0.526309 0.000000 -0.933484
v -0.246191 0.000000 -0.742184
v -0.030979 0.000000 -0.721688
v 0.320876 0.000000 -0.906155
v 0.426774 0.000000 -0.998389
v 0.809373 0.000000 -0.028226
v 0.963096 0.000000 0.268972
v 1.021170 0.000000 0.521761
v -0.597239 0.000000 -1.045599
v -0.593807 0.000000 -1.118817
v -0.565206 0.000000 -1.162291
v -0.296358 0.000000 -1.378513
v -0.187675 0.000000 -1.436858
v -0.087000 0.000000 -1.428850
v -0.067551 0.000000 -1.428850
v 0.336293 0.000000 -1.242373
v 0.436968 0.000000 -1.176019
v 0.433535 0.000000 -1.042167
v 0.299684 0.000000 -1.143986
v 0.168120 0.000000 -1.031871
v -0.044671 0.000000 -0.925476
v -0.175090 0.000000 -0.896875
v -0.330679 0.000000 -0.941492
v -0.517156 0.000000 -1.107377
v -0.202547 0.000000 -1.463171
v -0.215132 0.000000 -1.594735
v -0.302078 0.000000 -1.725155
v -0.383305 0.000000 -1.770916
v -0.485124 0.000000 -1.761764
v -0.564062 0.000000 -1.670241
v -0.598383 0.000000 -1.563846
v -0.589231 0.000000 -1.387665
v -0.495420 0.000000 -1.250381
v -0.425634 0.000000 -1.303006
v -0.347840 0.000000 -1.523805
v -0.394745 0.000000 -1.582151
v -0.467963 0.000000 -1.559270
v -0.494276 0.000000 -1.449443
v -0.435930 0.000000 -1.324743
v -0.065263 0.000000 -1.598167
v -0.019502 0.000000 -1.679394
v 0.089181 0.000000 -1.790365
v 0.202441 0.000000 -1.826974
v 0.363749 0.000000 -1.746891
v 0.458704 0.000000 -1.594735
v 0.448408 0.000000 -1.420842
v 0.422095 0.000000 -1.331607
v 0.380910 0.000000 -1.291566
v 0.235618 0.000000 -1.292710
v 0.059436 0.000000 -1.388809
v 0.092613 0.000000 -1.543254
v 0.168120 0.000000 -1.626768
v 0.253922 0.000000 -1.630200
v 0.316844 0.000000 -1.516941
v 0.284811 0.000000 -1.371649
v -0.291791 0.000000 -1.293205
v -0.212715 0.000000 -1.342627
v -0.080264 0.000000 -1.338673
v -0.003165 0.000000 -1.305066
l 1 2
l 3 4
l 5 6
l 7 8
l 11 12
l 13 14
l 1 15
l 17 18
l 19 20
l 21 22
l 23 24
l 25 26
l 27 28
l 29 30
l 31 32
l 33 34
l 35 36
l 37 38
l 19 39
l 31 40
l 41 42
l 45 46
l 47 48
l 49 50
l 51 52
l 53 54
l 55 56
l 57 58
l 59 60
l 61 62
l 63 64
l 65 66
l 67 68
l 69 70
l 71 72
l 9 73
l 73 74
l 75 76
l 77 78
l 79 80
l 81 82
l 85 86
l 87 88
l 89 90
l 91 92
l 93 94
l 95 96
l 89 97
l 97 98
l 101 102
l 105 106
l 107 108
l 109 110
l 111 112
l 101 113
l 113 114
l 115 116
l 117 118
l 119 120
l 99 121
l 121 122
l 123 124
l 125 126
l 103 128
l 129 130
l 131 132
l 133 134
l 135 136
l 137 138
l 139 140
l 141 142
l 137 143
l 36 43
l 93 106
l 122 127
l 2 3
l 4 5
l 6 7
l 8 9
l 10 11
l 12 13
l 14 15
l 10 16
l 16 17
l 18 19
l 20 21
l 22 23
l 24 25
l 26 27
l 28 29
l 30 31
l 32 33
l 34 35
l 36 37
l 38 39
l 40 41
l 42 43
l 42 44
l 44 45
l 46 47
l 30 49
l 50 51
l 52 53
l 54 55
l 56 57
l 58 59
l 60 61
l 62 63
l 64 65
l 66 67
l 68 69
l 70 71
l 10 73
l 74 75
l 76 77
l 78 79
l 80 81
l 82 83
l 80 84
l 84 85
l 86 87
l 88 89
l 90 91
l 92 93
l 94 95
l 98 99
l 100 101
l 102 103
l 104 105
l 108 109
l 110 111
l 114 115
l 116 117
l 118 119
l 120 121
l 100 122
l 100 123
l 124 125
l 126 127
l 128 129
l 130 131
l 132 133
l 134 135
l 104 136
l 104 137
l 138 139
l 140 141
l 142 143
l 144 145
l 146 147
l 7 72
l 103 138
l 43 96
`

export default tux
