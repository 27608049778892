import CaptionedImage from '../CaptionedImage.js'

import { Trans, useTranslation } from 'react-i18next'



export default function Killroy(){
	const {t, i18n} = useTranslation("killroy");
	return (
		<div>
			<h4>{t('title')}</h4>
			<br />
			<p>
				{t('c1')}
			</p>
			<p>
				{t('c2')}
			</p>
			<CaptionedImage src="/images/lobby.png" caption={t('cap1')} />
			<p>
				{t('c3')}
			</p>
			<CaptionedImage src="/images/dialog.png" caption={t('cap2')} />
			<CaptionedImage src="/images/breakroom.png" caption={t('cap3')} />
			<CaptionedImage src="/images/computer.png" caption={t('cap4')} />
			<p>
				{t('c4')}
			</p>
			<p>
				{t('c5')}
			</p>
		</div>
	)

}


