import CaptionedVideo from '../CaptionedVideo.js'
import CaptionedImage from '../CaptionedImage.js'
import {Link} from 'react-router-dom'

import { Trans, useTranslation } from 'react-i18next'


export default function Chatbot(){
	const {t, i18n} = useTranslation("chatbot");
	return (
		<div>
			<h4>{t('title')}</h4>
			<br />
			<p>
				{t('c1')}
			</p>
			<CaptionedImage src="/images/sonnenbrille.png" caption={t('cap1')} />

			<p>
				<Trans i18nKey="c2" t={t}>
					x
					<a href="https://chatbot.marvinwyss.ch">y</a>
					z
				</Trans>
			</p>
			<p>
				{t('c3')}
			</p>
			<CaptionedImage src="/images/companyquestion.png" caption={t('cap2')} />
			<p>
				{t('c4')}
			</p>
			<p>
				{t('c5')}
			</p>
			<CaptionedImage src="/images/druckdialog.png" caption={t('cap3')} />
			<p>
				{t('c6')}
			</p>
		</div>
	)

}



