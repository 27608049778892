import LBImage from "./LBImage";

export default function ListItem({img,children, className, style,width=150,height=150}) {

	return (
		<div className={"d-flex flex-column flex-md-row m-3 "+className} style={style}>
			<div className="mb-4 mb-md-0">
				{
					<LBImage src={img} width={width} height={height} alt="404" className={"ms-auto "} isIcon={true} />

				}
			</div>
			<div className="flex-grow-1 ms-3">
				{children}
			</div>
		</div>
	)

}
