import LBVideo from './LBVideo.js'

export default function CaptionedVideo({src, caption}){
	return (
		<div className="p-3 d-flex w-100 flex-column">
			<LBVideo src={src} className="mx-auto m-2" width="100%" maxHeight="300px"/>
			<p className="mx-auto m-2">{caption}</p>
		</div>
	)
			//<video className="mx-auto m-2" src={src}  height="300" alt="404" controls/>

}
