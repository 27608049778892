import { Link } from 'react-router-dom'
import CaptionedImage from '../CaptionedImage'

import { Trans, useTranslation } from 'react-i18next'


export default function Tierheim(){
	const {t, i18n} = useTranslation("tierheim");


	return (
		<div>
			<h4>{t('title')}</h4>
			<br />
			<p>
				<Trans i18nKey="c1" t={t} >
					x
					<Link to="//tierheim.marvinwyss.ch">y </Link>
					z
					<b>w</b>
					u 
					<b>v</b>
					t
				</Trans>
			</p>



					<p>{t('c2')}</p>
					<p>{t('c3')}</p>
					<CaptionedImage src="/images/aarebrueggli.jpg" caption={t('cap1')}/>
					<p>{t('c4')}</p>
					<p>{t('c5')}</p>
					<CaptionedImage src="/images/masterplan.png" caption={t('cap2')}/>
					<p>{t('c6')}</p>
					<CaptionedImage src="/images/list.png" caption={t('cap3')}/>
					<CaptionedImage src="/images/dash.png" caption={t('cap4')}/>
					<p>{t('c7')}</p>
					<CaptionedImage src="/images/mobile.png" caption={t('cap5')}/>
					<p>{t('c8')}</p>
					<CaptionedImage src="/images/apidoc.png" caption={t('cap6')}/>
					<p>{t('c9')}</p>
					<p>{t('c10')}</p>
		</div>
					)

					}


