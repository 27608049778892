import GenericContainer from './GenericContainer.js'
import ListItem from './ListItem.js'
import {useTrail, useSpring, animated} from 'react-spring'


export default function AnimatedList({children,numElements}){

	const trails = useTrail(numElements, {
		//from: { transform:"translate(20vw)" },
		//to: { transform:"translate(0vw)" },
		from: { opacity:0,transform:"translate(-20vw)" },
		to: { opacity:1 ,transform:"translate(0vw)"},
		config: { duration: 300},
		delay: 100

	})



	return(
		<GenericContainer className="d-flex flex-column " style={{gap:"10pt"}}>
			{trails.map((props,i) => (
				<animated.div key={i} style={props}>
					{children(i)}
				</animated.div>
			)
			)
			}

		</GenericContainer>
	)
}

