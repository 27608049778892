import CaptionedImage from '../CaptionedImage.js'

import { Trans, useTranslation } from 'react-i18next'


export default function Alarm(){
	const {t, i18n} = useTranslation("alarm");

	return (
		<div>
			<h4>{t('title')}</h4>
			<br />
			<p>
				{ t('c1') }
			</p>
			<CaptionedImage src="/images/clock.png" caption={ t('cap6') }/>
			<p>
				{ t('c3') }
			</p>
			<CaptionedImage src="/images/oldsensor.jpg" caption= { t('cap2') }/>
			<p>
				{ t('c4') }
			</p>
			<CaptionedImage src="/images/chip.jpg" caption= { t('cap4') }/>
			<CaptionedImage src="/images/newsensor.png" caption= { t('cap3') }/>
			<p>
				{ t('c5') }
			</p>
			<CaptionedImage src="/images/pcb.png" caption={ t('cap5') }/>
			<p>
				{ t('c6') }
			</p>
				<CaptionedImage src="/images/app.png" caption={ t('cap1') } />
			<p> 
				{ t('c7') }
			</p>
			<p>
				{ t('c8') }
			</p>
			<p>
				{ t('c9') }
			</p>

		</div>
	)

}

