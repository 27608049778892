
//import Bootstrap as*

import React from 'react';
import { Button,Modal, Form, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import { useTranslation} from "react-i18next";
import {useEffect, useState} from 'react';
import {Check,ThreeDots} from 'react-bootstrap-icons';

export default function QuickForm({ show, onHide }) {
	const {t, i18n} = useTranslation(["projects","ui"]);
	const [state, setState] = useState("idle");
	const [enabled, setEnabled] = useState(false);

	useEffect(()=>{
		setState("idle")
	}
		,[show]);

	// Formspree form, submit to post
	const onSubmit=(e)=>{
		setState("loading");
		e.preventDefault();
		const form = e.target;
		const data = new FormData(form);
		console.log(data)
		const xhr = new XMLHttpRequest();
		xhr.open(form.method, form.action);
		xhr.setRequestHeader("Accept", "application/json");
		xhr.onreadystatechange = () => {
			if (xhr.readyState !== XMLHttpRequest.DONE) return;
			if (xhr.status === 200) {
				form.reset();
				setState("success");
			} else {
				alert("Failed to send message");
			}
		};
		xhr.send(data);
	}


	const spinner=<ThreeDots animation="border" role="status" className="ms-1"/>
	const check=<Check className="ms-1"/>
	return (
		<Modal show={show} onHide={onHide}>

			<Form
				onSubmit={onSubmit}
				action="https://formspree.io/f/xbjnpvlq"
				method="POST"
			>

				<Modal.Header closeButton>
					<Modal.Title>{t("ui:contactform")}</Modal.Title>
				</Modal.Header>

				<Modal.Body>

					<FormGroup>
						<Form.Label>{t("ui:message")}</Form.Label>
						<FormControl name="message" as="textarea" rows={3} required/>
					</FormGroup>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={onHide}>
						{t("ui:cancel")}
					</Button>
					<Button variant="primary" type="submit">
						{t("ui:send")}
						{
							state==="loading"?spinner:state==="success"?check:""
						}
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>

	);
}
