import { Trans, useTranslation } from 'react-i18next'
import {Link} from 'react-router-dom'
import CaptionedImage from '../CaptionedImage.js'


export default function Jobquery(){
	const {t, i18n} = useTranslation("jobquery");
	return (
		<div>
			<h4>{t('title')}</h4>
			<br />
			<p>
				<Trans i18nKey="c1" t={t}>
					a
					<Link to="https://jobquery.marvinwyss.ch">b</Link>
					c
				</Trans>
			</p>
			<p>
				{t('c2')}
			</p>
				<CaptionedImage src="/images/jqjobs.png" caption={t('capjobs')} />
			<p>
				{t('c3')}
			</p>
			<p>
				{t('c4')}
			</p>
		</div>
	)

}


