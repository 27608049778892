export function getThumbnail(path) {
	//turn "/images/whatever.jpg" into "/images/thumbnails/whatever_thumb.jpg"
	
	//split path into array
	let pathArray = path.split("/");

	//get last element of array
	let lastElement = pathArray[pathArray.length - 1];


	return "/images/thumbnails/"+lastElement + "_thumb.jpg"

}
