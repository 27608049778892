import {useContext,useEffect, useState} from 'react';
import {LightBoxContext} from './LightBoxContext.js'
import {getThumbnail} from '../common/getThumbnail.js'

export default function LBImage({src,width,height,maxHeight,className,imgClassName,isIcon}) {
	const {lightBoxImages, addLightBoxImage, removeLightBoxImage, openLightBox} = useContext(LightBoxContext);

	const [errorImage, setErrorImage] = useState(null);

	const onError = (e) => {
		setErrorImage("images/404.png");
		removeLightBoxImage(src)
	};
	useEffect(() => {
		setErrorImage(null);
	}, [src]);

	useEffect(()=>{
		addLightBoxImage({src})
		return ()=>{removeLightBoxImage(src)}
	},[])
	


	const config ={
		src:errorImage?? (isIcon?getThumbnail(src):src),
		role:errorImage?undefined:"button",
		className:imgClassName,
		width:width,
		height:height,
		style:{maxHeight,maxWidth:width?"":"100%"},
		onError:onError ,
		onClick:()=>openLightBox(src)
	}
	const imgJsx=isIcon?
		(
		<>
			{
				//render two images, one for md and below, one for above
				[0,1].map((i)=>(
					<img key={i} {...config} className={imgClassName+(i==0?"d-block d-md-none img-fluid w-100 mw-100":" d-none d-md-block")}/>
					))
			}
		</>
		)
		:
		<img {...config} alt="404"/>
	

	return (
		<div className={"LBImage "+className}>
			{imgJsx}
		</div>
	);

}
