import {useState, useEffect} from 'react'
import GenericContainer from './GenericContainer.js'
import ListItem from './ListItem.js'
import {useTrail, useSpring, animated} from 'react-spring'
import AnimatedList from './AnimatedList.js'
import { Link } from 'react-router-dom';
import LBImage from './LBImage.js'
import {getThumbnail} from '../common/getThumbnail.js'

import { Trans, useTranslation } from 'react-i18next'



export default function Projects(){
	const {t, i18n} = useTranslation(["projects","ui"]);
	const [sort, setSort] = useState("relevance")



	const templates=[
		{
			title:t('lounge.title'),
			employer:t('lounge.employer'),
			time:"2006-2015",
			team:t('lounge.team'),
			description:t('lounge.description'),
			image:"/images/lounge.jpg",
			link:"/projects/lounge",
			relevance:8.51,
			duration:5,
		},
		/*
		{
			title:t('faszination.title'),
			employer:t('faszination.employer'),
			time:"2008-2011",
			team:t('faszination.team'),
			description:t('faszination.description'),
			image:"fm.png",
			link:"/projects/faszination",
			relevance:3.2,
		},
		*/
		{
			title:t('spesen.title'),
			employer:t('spesen.employer'),
			time:"2013-2015",
			team:t('spesen.team'),
			description:t('spesen.description'),
			image:"/images/spesen.png",
			link:"/projects/spesen",
			relevance:2.3,
			duration:1,
		},
		{
			title:t('reception.title'),
			employer:t('reception.employer'),
			time:"2015",
			team:t('reception.team'),
			description:t('reception.description'),
			image:"/images/reception.jpg",
			link:"/projects/reception",
			relevance:5.4,
			duration:1,

		},
		{
			title:t('hlmsds.title'),
			employer:t('hlmsds.employer'),
			time:"2015-2018",
			team:t('hlmsds.team'),
			description:t('hlmsds.description'),
			image:"/images/hlms_ds.png",
			link:"/projects/hlmsds",
			relevance:10.5,
			duration:10,

		},
		{
			title:t('icarus.title'),
			employer:t('icarus.employer'),
			time:"2017-2018",
			team:t('icarus.team'),
			description:t('icarus.description'),
			image:"/images/icarus.jpg",
			link:"/projects/icarus",
			relevance:7.6,
			duration:8,
		},
		{
			title:t('tierheim.title'),
			employer:t('tierheim.employer'),
			time:"2018-2019",
			team:t('tierheim.team'),
			description:t('tierheim.description'),
			image:"/images/tierheim.png",
			link:"/projects/tierheim",
			relevance:6.7,
			duration:4,
			live:"https://tierheim.marvinwyss.ch"
		},
		{
			title:t('compiler.title'),
			employer:t('compiler.employer'),
			time:"2019",
			team:t('compiler.team'),
			description:t('compiler.description'),
			image:"/images/compiler.png",
			link:"/projects/compiler",
			relevance:5.8,
			duration:4,
		},
		{
			title:t('killroy.title'),
			employer:t('killroy.employer'),
			time:"2019-2020",
			team:t('killroy.team'),
			description:t('killroy.description'),
			image:"/images/killroy.png",
			link:"/projects/killroy",
			relevance:3.9,
			duration:2,
		},
		{
			title:t('raytracer.title'),
			employer:t('raytracer.employer'),
			time:"2019",
			team:t('raytracer.team'),
			description:t('raytracer.description'),
			image:"/images/raytracer.png",
			link:"/projects/raytracer",
			relevance:7.0,
			duration:6
		},
		{
			title:t('lotto.title'),
			employer:t('lotto.employer'),
			time:"2020",
			team:t('lotto.team'),
			description:t('lotto.description'),
			image:"/images/lotto.png",
			link:"/projects/lotto",
			relevance:4.11,
			duration:2
		},
		{
			title:t('personenstau.title'),
			employer:t('personenstau.employer'),
			time:"2020",
			team:t('personenstau.team'),
			description:t('personenstau.description'),
			image:"/images/sbb.png",
			link:"/projects/personenstau",
			relevance:7.12,
			duration:3
		},
		{
			title:t('alarm.title'),
			employer:t('alarm.employer'),
			time:"2021-2022",
			team:t('alarm.team'),
			description:t('alarm.description'),
			image:"/images/clock.png",
			link:"/projects/alarm",
			relevance:7.9,
			duration:3

		},
		{
			title:t('dr.title'),
			employer:t('dr.employer'),
			time:"2022-2023",
			team:t('dr.team'),
			description:t('dr.description'),
			image:"/images/home.jpg",
			link:"/projects/dr",
			relevance:9.13,
			duration: 4
		},
		{
			title:t('compression.title'),
			employer:t('compression.employer'),
			time:"2022",
			team:t('compression.team'),
			description:t('compression.description'),
			image:"/images/compression.png",
			link:"/projects/compression",
			relevance:6.15,
			duration:1
		},
		{
			title:t('website.title'),
			employer:t('website.employer'),
			time:"2022-2023",
			team:t('website.team'),
			description:t('website.description'),
			image:"/images/website.png",
			link:"/projects/website",
			relevance:6.16,
			duration:2
		},
		{
			title:t('tagflip.title'),
			employer:t('tagflip.employer'),
			time:"2021-2023",
			team:t('tagflip.team'),
			description:t('tagflip.description'),
			image:"/images/tfpost.png",
			link:"/projects/tagflip",
			relevance:9.1,
			duration:6,
			live:"https://tagflip.marvinwyss.ch"

		},
		{
			title:t('jobquery.title'),
			employer:t('jobquery.employer'),
			time:"2023",
			team:t('jobquery.team'),
			description:t('jobquery.description'),
			image:"/images/jqjobs.png",
			link:"/projects/jobquery",
			relevance:7.1,
			duration:2,
			live:"https://jobquery.marvinwyss.ch"
		},
		{
			title:t('vector.title'),
			employer:t('vector.employer'),
			time:"2023",
			team:t('vector.team'),
			description:t('vector.description'),
			image:"/images/vector.png",
			link:"/projects/vector",
			relevance:6.1,
			duration:2,
			live:"https://vector.marvinwyss.ch"
		},
		{
			title:t('chatbot.title'),
			employer:t('chatbot.employer'),
			time:"2023-2024",
			team:t('chatbot.team'),
			description:t('chatbot.description'),
			image:"/images/chatbot.png",
			link:"/projects/chatbot",
			relevance:10.1,
			duration:5,
			live:"https://chatbot.marvinwyss.ch"
		},


	]

	const finalTemplates=sort==="relevance"?templates.sort((a,b)=>b.relevance-a.relevance):templates

	return(
		<GenericContainer className="overflow-auto flex-grow-1 d-flex flex-column mb-4" style={{gap:"10pt"}}>
			<div className=" m-4">
				<h1>{t('title')}</h1>
				<br/>
				<p>
					{t('description')}
				</p>
				<p role="button" className="text-white" onClick={()=>setSort(s=>s==="relevance"?"time":"relevance")}>
					<i className="pe-1 bi bi-caret-down-fill"></i>
					{t('ui:'+sort)}
				</p>
			</div>
			<AnimatedList numElements={templates.length} children={(i)=>(
				<ListItem  img={templates[i].image}>
					<div>
						<h5>
							<Link to={templates[i].link}>{templates[i].title}</Link>
						</h5>
					</div>
					<p>
						<b>{templates[i].employer}</b> -  
						<span>{templates[i].time}</span>
						- <b>{templates[i].team}</b>
						- <span> {templates[i].duration} {(templates[i].duration===1?t('ui:minute'):t('ui:minutes'))+" "+t('ui:readingtime')}</span>
					</p>
					{
						templates[i].live?
							<p><Link className="text-decoration-none text-primary" to={templates[i].live}><b>{t('ui:live')}</b></Link></p>
							:null
					}
					<p>{templates[i].description}</p>
					<p><Link  className="text-white text-decoration-none" to={templates[i].link}><b>{t('ui:readmore')}</b></Link></p>
				</ListItem>
			)} />

		</GenericContainer>
	)
}
