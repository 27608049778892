import * as THREE from 'three';
import Stats from 'three/examples/jsm/libs/stats.module'
import Chance from 'chance';
import {loadObj,makeSamples} from './model.js';
import tux from './tux.obj.js'
const chance = new Chance();


export default class Scene{

	constructor(domElement){
		this.camera=null
		this.stars=null
		this.scene=null
		this.renderer=null
		this.animateHandler=null
		this.stats=null
		this.dummy=new THREE.Object3D()
		this.mousePos={x:0,y:0}
		this.mouseTracker=null
		this.timer=0
		this.height=0
		this.width=0
		this.animOffset=-1
		this.shootingStar=null

		this.shootingStarCaught=false

		this.debugCircle1=null
		this.debugCircle2=null
		this.debugLine=null

		this.domElement=domElement
		this.init()

		this.outerRimRadius=Math.max(this.width,this.height)*0.8
		this.targetRadius=Math.min(this.width,this.height)*.6
		this.center={x:this.width/2,y:this.height/2}

		if(this.outerRimRadius<this.targetRadius){
			throw new Error("outer radius too small")
		}
	}


	respawnShootingStar(milis){



		//get random point on outer rim
		let angle=chance.floating({min:0,max:Math.PI*2})
		let newPos={
			x:this.center.x+Math.cos(angle)*this.outerRimRadius,
			y:this.center.y+Math.sin(angle)*this.outerRimRadius	
		}


		this.shootingStar.model.position.set(newPos.x,newPos.y,-200)

		//choose target angle

		let distx=this.center.x-this.shootingStar.model.position.x
		let disty=this.center.y-this.shootingStar.model.position.y

		let dist=Math.sqrt(distx*distx+disty*disty)

		angle=Math.atan2(disty,distx)
		let maxAngle=Math.asin(this.targetRadius/dist)

		//choose an new vector angle

		//let newAngle=chance.floating({min:angle-maxAngle,max:angle+maxAngle})
		let newAngle=angle+maxAngle
		let newVec=new THREE.Vector2(Math.cos(newAngle),Math.sin(newAngle))
		newAngle=angle-maxAngle
		let newVec2=new THREE.Vector2(Math.cos(newAngle),Math.sin(newAngle))
		let randAngle=angle+chance.floating({min:-maxAngle,max:maxAngle})
		let randVec=new THREE.Vector2(Math.cos(randAngle),Math.sin(randAngle))


		this.shootingStar.direction=new THREE.Vector3(randVec.x,randVec.y,0)
		this.shootingStar.respawn=false
		//finally rotate shooting star in direction of travel
		this.shootingStar.model.rotation.z=Math.atan2(randVec.y,randVec.x)





		/*

		this.scene.remove(this.debugLine)

		const d_points = [];
		d_points.push( new THREE.Vector3( newPos.x,newPos.y, -200 ) );
		d_points.push( new THREE.Vector3( newVec.x*10000+newPos.x, newVec.y*10000+newPos.y, -200 ) );
		d_points.push( new THREE.Vector3( newPos.x,newPos.y, -200 ) );
		d_points.push( new THREE.Vector3( newVec2.x*10000+newPos.x, newVec2.y*10000+newPos.y, -200 ) );
		d_points.push( new THREE.Vector3( newPos.x,newPos.y, -200 ) );
		d_points.push( new THREE.Vector3( randVec.x*10000+newPos.x, randVec.y*10000+newPos.y, -200 ) );

		const bg_line= new THREE.BufferGeometry().setFromPoints( d_points );

		this.debugLine=new THREE.Line(
			bg_line,
			new THREE.LineBasicMaterial( { color: 0x00ff00 } )
		)

		this.scene.add(this.debugLine)




		this.debugCircle2.position.set(this.center.x,this.center.y,-200)
		this.debugCircle2.scale.set(this.targetRadius,this.targetRadius,1)

		this.debugCircle1.position.set(this.center.x,this.center.y,-200)
		this.debugCircle1.scale.set(this.outerRimRadius,this.outerRimRadius,1)
		*/
	}

	moveShootingStar(milis,advance){

		this.shootingStar.model.position.add(this.shootingStar.direction.clone().multiplyScalar(advance*this.shootingStar.speed))

		//set respawn flag as soon as past outter rim

		let pos2D=new THREE.Vector2(this.shootingStar.model.position.x,this.shootingStar.model.position.y)


		let distToCenter=pos2D.distanceTo(new THREE.Vector2(this.center.x,this.center.y))

		if(distToCenter>this.outerRimRadius && !this.shootingStar.timeoutActive && !this.shootingStarCaught){
			this.shootingStar.timeoutActive=true
			setTimeout(()=>{
				if(!this.shootingStarCaught){
					this.shootingStar.respawn=true
					this.shootingStar.timeoutActive=false
				}
			},15000)
		}


		this.shootingStar.hitcircle.center=this.shootingStar.model.position.clone().add(this.shootingStar.direction.clone().multiplyScalar(150))

		/*
		this.debugCircle1.position.set(this.shootingStar.hitcircle.center.x,this.shootingStar.hitcircle.center.y,-200)
		this.debugCircle1.scale.set(this.shootingStar.hitcircle.radius,this.shootingStar.hitcircle.radius,1)
		*/
	}

	init(){
		//2D Camera
		//let width=this.domElement.clientWidth
		//let height=this.domElement.clientHeight

		let canvasWidth=this.domElement.clientWidth
		let canvasHeight=this.domElement.clientHeight

		let aspect=canvasWidth/canvasHeight


		let width=canvasWidth
		let height=canvasHeight
		//use correct density 0.005 per pixel
		let density=0.007


		let numStars=Math.floor(width*height*density);

		let lines=loadObj(tux)
		this.tux=makeSamples(lines,numStars,width,height)



		this.scene=new THREE.Scene()
		this.camera=new THREE.OrthographicCamera(0, width,height, 0, 1, 1000)






		/*
		for(let i=0;i<numStars;i++){
			//generate quad
			let star=new THREE.Mesh(
				new THREE.PlaneGeometry(100,100),
				new THREE.MeshBasicMaterial({
					color:Math.random()*0xffffff,
					transparent:true,

				})
			)
			star.position.set(
				Math.random()*width,
				Math.random()*height,
				Math.random()*-200
			)
			star.scale.set(
				Math.random()*2,
				Math.random()*2,
				Math.random()*2
			)
			this.stars.push(star)
			this.scene.add(star)
		}
		*/
			//quad geometry


			//stars shader material
			let instancedShaderMaterial=new THREE.ShaderMaterial({
				vertexShader:`
				attribute vec4 starData;
				//out uv
				varying vec2 vUv;
				//out screenpos
				varying vec2 vScreenPos;
				varying vec4 vStarData;
				varying float instanceScale;


				uniform vec2 resolution;




				void main(){
					gl_Position=projectionMatrix*modelViewMatrix*instanceMatrix*vec4(position,1.0);
					vUv=uv;
					vScreenPos=(modelViewMatrix*instanceMatrix*vec4(position,1.0)).xy;
					vStarData=starData;
					//get scale from instanceMatrix
					instanceScale=instanceMatrix[0][0];
				}
			`,
			fragmentShader:`
				
				varying vec4 vStarData;
				varying vec2 vUv;
				varying vec2 vScreenPos;
				varying float instanceScale;

				uniform float timer;
				uniform vec2 mousePos;
				uniform vec2 resolution;

				float myLength(vec2 v){

					return abs(v.x)+abs(v.y);

				}

				void main(){
					vec3  chroma=vStarData.xyz;
					float brightness=vStarData.w;

					vec2 center=vec2(0.5,0.5);
					vec2 cDist=center-vUv;


					float intensity=10.0/(myLength(cDist)*600.0)-0.02;
					//intensity=brightness*intensity;
					

						
					vec3 color=chroma*step(0.0,intensity)*intensity;

					//add mouse tracker
					//color+=step(-0.5,-myLength(mousePos-vScreenPos))*vec3(1,0,0);
					//color=vec3(100.0/myLength(vScreenPos-mousePos));

					/*
					float borderWidth=0.2/instanceScale;
					if(abs(cDist.x)>(0.5-borderWidth)||abs(cDist.y)>(0.5-borderWidth)){
						color+=vec3(0.1,0,0);
					}
					*/
					/*	
					float borderWidth=0.8/instanceScale;
					if(myLength(cDist)>(0.5-borderWidth)){
						color+=vec3(0.1,0,0);
					}
					*/


					float distToStarCenter=length(mousePos-vScreenPos-vec2(cDist*5.0*instanceScale));
					if(distToStarCenter<5.0*instanceScale){
						color*=1.7;
					}

					gl_FragColor=vec4(color,1.0);
				}
			`,
			//additive blending
			blending:THREE.AdditiveBlending,
			transparent:true,
			depthTest:false,
			depthWrite:false,

			uniforms:{
				//time
				time:{value:0},
				//mouse position
				mousePos:{value:new THREE.Vector2(0,0)},
				//resolution
				resolution:{value:new THREE.Vector2(width,height)},

			}
		})

		this.shootingStar={
			length:7,
			position:new THREE.Vector3(700,400,-200),
			rotation:0,
			model:null,
			direction:null,
			respawn:true,
			speed:Math.min(Math.min(width,height)/800,.8),
			hitcircle:{
				center:new THREE.Vector3(),
				radius:120
			},
			timeoutActive:false

		}

		let shootingStarMaterial=new THREE.ShaderMaterial({
			vertexShader:`
				//out uv
				varying vec2 vUv;
				//out screenpos
				varying vec2 vScreenPos;


				void main(){
					gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
					vUv=uv;
					vScreenPos=(modelViewMatrix*vec4(position,1.0)).xy;
				}
			`,
			fragmentShader:`
				
				varying vec2 vUv;
				varying vec2 vScreenPos;
				uniform vec2 resolution;
				uniform float sLength;
				uniform float caught;

				float distToLine(vec2 lStart, vec2 lEnd, vec2 point) {
					vec2 lineDir = normalize(lEnd - lStart);
					vec2 pointDir = point - lStart;
					float projection = dot(pointDir, lineDir);

					// Calculate the closest point on the line
					vec2 closestPoint = lStart + max(0.0, min(projection, distance(lStart, lEnd))) * lineDir;

					// Calculate the distance between the point and the closest point on the line
					return distance(point, closestPoint);
				}
				void main(){
					//normalize uv position
					vec2 lStart=vec2(0.9,0.5/sLength);
					vec2 lEnd=vec2(0.1,0.5/sLength);
					vec2 nvUv=vec2(vUv.x,vUv.y/sLength);

					float distToFront=max(0.1,min(0.9,nvUv.x));


					float dist=distToLine(lStart,lEnd,nvUv);

					float distIntens=pow(dist,1.1)/1.0;
					float tipIntens=pow(distToFront,3.0);

					float intens=max(0.0,tipIntens/(distIntens*400.0)-0.04);
					//add more tip intensity
					intens+=0.002/distance(nvUv,lStart);


					vec3 color=vec3(intens);
					if(caught>0.5){
						color=vec3(intens,0,0);
					}


					/*
					vec2 center=vec2(0.5,0.5/sLength);
					vec2 cDist=center-nvUv;
					float borderWidth=0.02;
					if(max(abs(cDist.x),abs(cDist.y))>(0.5-borderWidth)){
						color+=vec3(0.2,0,0);
					}
					*/

					gl_FragColor=vec4(color,1.0);
				}
			`,
			//additive blending
			blending:THREE.AdditiveBlending,
			transparent:true,
			depthTest:false,
			depthWrite:false,

			uniforms:{
				resolution:{value:new THREE.Vector2(width,height)},
				sLength:{value:this.shootingStar.length},
				caught:{value:0.0}
			}
		})

		this.height=height
		this.width=width

		//add shooting star quad
		this.shootingStar.model=new THREE.Mesh(
			new THREE.PlaneGeometry(50*this.shootingStar.length,50),
			shootingStarMaterial
		)

		this.shootingStar.model.position.set(this.shootingStar.position.x,this.shootingStar.position.y,this.shootingStar.position.z)

		//add to scene
		this.scene.add(this.shootingStar.model)


		//debug items
		/*
		this.debugCircle1=new THREE.Mesh(
			new THREE.CircleGeometry(1,32),
			new THREE.MeshBasicMaterial({
				color: 0xff0000,
				wireframe: true
			})
		)
		this.debugCircle1.position.set(200,200,-200)
		this.scene.add(this.debugCircle1)


		this.debugCircle2=new THREE.Mesh(
			new THREE.CircleGeometry(1,32),
			new THREE.MeshBasicMaterial({
				color: 0x0000ff,
				wireframe: true
			})
		)

		this.debugCircle2.position.set(300,300,-200)
		this.scene.add(this.debugCircle2)


			const d_points = [];
		d_points.push( new THREE.Vector3( 100, 0, -200 ) );
		d_points.push( new THREE.Vector3( 0, 100, -200 ) );

		const bg_line= new THREE.BufferGeometry().setFromPoints( d_points );

		this.debugLine=new THREE.Line(
			bg_line,
			new THREE.LineBasicMaterial( { color: 0x00ff00 } )
		)

		this.scene.add(this.debugLine)
		*/













		
		// Create an array of chroma and brightness for the instances
		const instanceData = new Float32Array( numStars * 4 );


		//this.stars= new THREE.InstancedMesh( quadGeometry,instancedShaderMaterial, numStars );


		//create geometry of a rhombus with vertices (0,1),(1,0),(0,-1),(-1,0) and corresponding uv (0.5,1),(1,0.5),(0.5,0),(0,.5)

	

		let geometry = new THREE.BufferGeometry()
		let v_size=2.5
		//create a diagonal rectangle with upright UVs (a quadratic image with all the corners folded to the back)
		const points = [
			new THREE.Vector3(-v_size,0,0),
			new THREE.Vector3(0,-v_size,0),
			new THREE.Vector3(0,v_size,0),

			new THREE.Vector3(0,-v_size,0),
			new THREE.Vector3(v_size,0,0),
			new THREE.Vector3(0,v_size,0),
		]

		geometry.setFromPoints(points)
		geometry.computeVertexNormals()
		//uv
		let uvs=new Float32Array([
			0,.5,
			.5,0,
			.5,1,

			.5,0,
			1,.5,
			.5,1
		])
		geometry.setAttribute( 'uv', new THREE.BufferAttribute( uvs, 2 ) );
		

		this.stars= new THREE.InstancedMesh( geometry, instancedShaderMaterial, numStars );



		this.stars.instanceMatrix.setUsage( THREE.DynamicDrawUsage )

		for(let i=0;i<numStars;i++){
			//set random positions
			let matrix=new THREE.Matrix4()
			matrix.setPosition(
				Math.random()*width,
				Math.random()*height,
				//Math.random()*-200
				-200	
			)
			//set random scale
			let scales=[2,5,15]
			let probs=[0.5,0.2,0.02]
			let scale=scales[probs.findIndex((p)=>Math.random()<p)]
			matrix.scale(new THREE.Vector3(scale,scale,1))


			let chromas=[[1,1,1],[1,.7,.7],[.7,.7,1]]
			let chromaProbs=[12,2.2,2]

			let chroma=chance.weighted(chromas,chromaProbs);

			//update mesh
			this.stars.setMatrixAt(i,matrix)
			//update chroma and brightness
			instanceData[ i * 4 ] = chroma[0];
			instanceData[ i * 4 + 1 ] = chroma[1];
			instanceData[ i * 4 + 2 ] = chroma[2];
			instanceData[ i * 4 + 3 ] = Math.random();

		}

		this.stars.instanceMatrix.needsUpdate=true

		//set instance data
		this.stars.geometry.setAttribute( 'starData', new THREE.InstancedBufferAttribute( instanceData, 4, false ) );
		//set needs update
		this.stars.geometry.attributes.starData.needsUpdate=true



		this.scene.add( this.stars );




		/*
		//add fps counter
		this.stats=new Stats()
		this.stats.showPanel(0)
		this.domElement.appendChild(this.stats.dom)
		*/


		//render Scene
		this.renderer=new THREE.WebGLRenderer(
			{
				alpha:false,
				antialias:false
			}
		)
		//this.renderer.setClearColor( 0x000000, 1 );
		this.renderer.setClearColor( 0x000000, 1 );
		this.renderer.setPixelRatio(window.devicePixelRatio  );
		this.renderer.setSize(canvasWidth,canvasHeight)


		this.domElement.appendChild(this.renderer.domElement)
		this.renderer.render(this.scene,this.camera)

		//track mouse on canvas
		//this.domElement.addEventListener("mousemove",this.onMouseMove.bind(this))
		document.querySelector("body").addEventListener("mousemove",this.onMouseMove.bind(this))
		document.querySelector("body").addEventListener("click",this.onMouseClick.bind(this))


		//animate
		this.animateHandler=requestAnimationFrame(this.animate.bind(this))
	}

	onMouseClick(e){
		//check if we hit the shooting star

		let mousePos=new THREE.Vector3(this.mousePos.x,this.mousePos.y,-200)

		let distToShootingStar=mousePos.distanceTo(this.shootingStar.hitcircle.center)

		if(distToShootingStar<this.shootingStar.hitcircle.radius){
			this.shootingStarCaught=true
		}


	}
	onMouseMove(e){
		//trackMousePos on canvas
		this.mousePos.x=e.clientX
		this.mousePos.y=this.domElement.clientHeight-e.clientY

		

	}


	animate(milis){

		let advance=0
		if(this.animOffset!==-1){
			advance=milis-this.animOffset
		}
		this.animOffset=milis

	
		/*
		for(let i=0;i<this.stars.count;i++){
			let matrix=new THREE.Matrix4()
			this.stars.getMatrixAt(i,matrix)

			matrix.setPosition(this.tux[i][0],this.tux[i][1],-200)
			

			this.stars.setMatrixAt(i,matrix)
			
			
		}
		this.stars.instanceMatrix.needsUpdate=true
		*/
		


	
		if(this.shootingStarCaught){
			for(let i=0;i<this.stars.count;i++){

				let percent=i/this.stars.count
				let degree=Math.PI*2*Math.sqrt(percent)*4
				//let circlepos=new THREE.Vector3(this.width/2,this.height/2,-200).add(new THREE.Vector3(Math.cos(degree),Math.sin(degree),-200).multiplyScalar(Math.sqrt(percent)*Math.min(this.width,this.height)/2.5))
				let circlepos=new THREE.Vector3(this.tux[i][0],this.tux[i][1],-200)


				let matrix=new THREE.Matrix4()
				this.stars.getMatrixAt(i,matrix)
				//extract position from matrix
				let pos=new THREE.Vector3()

				pos.setFromMatrixPosition(matrix)

				//calculate distance to final position
				let dir=circlepos.clone().sub(pos)
				dir=dir.setZ(0)


				if(dir.length()>(Math.min(this.width,this.height)/170)){
					//normalize distance
					dir=dir.normalize()

					//move towards final position
					dir=dir.multiplyScalar(advance/10)
					pos.add(dir)
					//set new position
					matrix.setPosition(pos)



					this.stars.setMatrixAt(i,matrix)
				}



			}
			this.stars.instanceMatrix.needsUpdate=true
		}
		
		if(this.shootingStar.respawn)
			this.respawnShootingStar(milis)

		this.moveShootingStar(milis,advance)

		//this.mouseTracker.position.set(this.mousePos.x,this.mousePos.y,-10)

		//update uniforms
		this.timer+=milis/1000.0
		this.stars.material.uniforms.time.value=this.timer
		this.stars.material.uniforms.mousePos.value=this.mousePos


		



		this.renderer.render(this.scene,this.camera)
		//this.stats.update()




		this.animateHandler=requestAnimationFrame(this.animate.bind(this))
	}

	destroy(){
		cancelAnimationFrame(this.animateHandler)




		this.scene.children.forEach((child) => {   this.scene.remove(child); });
		this.domElement.removeChild(this.renderer.domElement)
		//this.domElement.removeChild(this.stats.dom)
		this.renderer.dispose()


		


		this.scene=null
		this.camera=null
		this.renderer=null
		//this.stats=null
		this.stars=null
		this.domElement.innerHTML=""
		

	}
}




