import LBImage from './LBImage'

export default function CaptionedImage({src, caption}){

	return (
		<div className="p-3 d-flex w-100 flex-column">
			<LBImage className="mx-auto m-2" src={src}   maxHeight="200pt" alt="404"/>
			<p className="mx-auto m-2">{caption}</p>
		</div>
	)
}
