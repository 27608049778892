import { Trans, useTranslation } from 'react-i18next'
export default function Personenstau(){
	const {t, i18n} = useTranslation("personenstau");

	return (
		<div>
			<h4>{t('title')}</h4>
			<br />

			<p>
				{ t('c1') }
			</p>
			<p>
				{ t('c2') }
			</p>
			<p>
				
				{ t('c3') }
			</p>
			<p>
				{ t('c4') }
			</p>
			<p>
				{ t('c5') }
			</p>
			<p>
				{ t('c6') }
			</p>
			<p>
				{ t('c7') }
			</p>
			<p>
				{ t('c8') }
			</p>

		</div>
	)

}


