import GenericContainer from "./GenericContainer.js"
import ListItem from "./ListItem.js"
import AnimatedList from "./AnimatedList.js"
import {Link} from 'react-router-dom'
import LBImage from './LBImage.js'
import {useTranslation} from 'react-i18next'


export default function Education(){
	const {t} = useTranslation(["education","ui"])


	const templates = [
		{
			school:t("coursera"),
			time:"2021-2023",
			type:t("certificate"),
			city:"Online",
			description:
			`
		`,
			image:"images/cursera.png",
			link:"/education/nlp",
			duration:2
		},
		{
			school:t('fhnw'),
			time:"2017-2022",
			type:t('bachelor'),
			city:"Brugg-Windisch",
			description:
			`
		`,
			image:"images/fhnw.png",
			link:"/education/fhnw",
			duration:5
		},
		{

			school:t('bbz'),
			time:"2016-2017",
			type:"Matura",
			city:"Olten",
			description:
			`
		`,
			image:"images/bbz.png",
			link:"/education/bbz",
			duration:1
		},
		{

			school:t("iso"),
			time:"2011-2013",
			type:t("efz"),
			city:"Olten",
			description:
			`
		`,
			image:"images/informatik.png",
			link:"/education/iso",
			duration:1
		},
		{

			school:t("kanti"),
			time:"2009-2011",
			type:"Matura",
			city:"Olten",
			description:
			`
		`,
			image:"images/kanti.png",
			link:"/education/kanti",
			duration:1
		},
		{

			school:t("ksg"),
			time:"2007-2009",
			type:t("secondaryschool"),
			city:"Neuendorf",
			description:
			`
		`,
			image:"images/neuendorf.png",
			link:"/education/kreis",
			duration:1
		},
		{
			school:t("gs"),
			time:"2000-2007",
			type:t("primaryschool"),
			city:"Härkingen",
			description:
			`
		`,
			image:"images/härkingen.png",
			link:"/education/pm",
			duration:1
		},
	]
	return (
		<GenericContainer className="overflow-auto flex-grow-1 d-flex flex-column mb-4" style={{gap:"10pt"}}>
			<div className="m-4">
				<h1>{t("ui:education")}</h1>
			</div>
			<AnimatedList numElements={templates.length} children={(i)=>(
				<ListItem  img={templates[i].image} >
					<h5>
						<div>
							<Link to={templates[i].link}>{templates[i].school}</Link>
						</div>
					</h5>
					<p>
						<b>{templates[i].type}</b> -  
						<span>{templates[i].time}</span> - 
						<b>{templates[i].city}</b> - 
						<span> {templates[i].duration} {templates[i].duration===1?t('ui:minute'):t('ui:minutes')} {t('ui:readingtime')}</span>
					</p>
					<p>{templates[i].description}</p>
					<p><Link  className="text-white text-decoration-none" to={templates[i].link}><b>{t('ui:readmore')}</b></Link></p>
				</ListItem>
			)} />

		</GenericContainer>
	)

}
