import CaptionedVideo from '../CaptionedVideo.js';
import CaptionedImage from '../CaptionedImage.js';

import { Trans, useTranslation } from 'react-i18next'


export default function Raytracer(){
	const {t, i18n} = useTranslation("raytracer");

	
	
	
	return (
		<div>
			<h4>Custom Raytracer</h4>
			<br />
			<p>{t('c1')}</p>
			<p>{t('c2')}</p>
			<CaptionedVideo src="/images/software.webm" caption={t('cap1')}/>
			<p>{t('c3')}</p>
			<CaptionedVideo src="/images/OpenGL.webm" caption={t('cap2')}/>
			<p>{t('c4')}</p>
			<p>{t('c5')}</p>
			<CaptionedImage src="/images/raytracer.png" caption={t('cap3')}/>
			<CaptionedImage src="/images/cornell_rt.png" caption={t('cap4')}/>
			<CaptionedImage src="/images/balls_rt.png" caption={t('cap5')}/>

			<p>{t('c6')}</p>
			<CaptionedImage src="/images/cornell_pt.png" caption={t('cap6')}/>
			<CaptionedImage src="/images/balls_pt.png" caption={t('cap7')}/>
			<p>{t('c7')}</p>
			<CaptionedImage src="/images/space_pt_early.png" caption={t('cap8')}/>
			<CaptionedImage src="/images/space_pt_late.png" caption={t('cap9')}/>

			<p>{t('c8')}</p>
			<CaptionedVideo src="/images/snowman.webm" caption={t('cap10')}/>

			<p>{t('c9')}</p>
		</div>
	)

}


