export default function Faszination(){
	return (
		<div>
			<h4>My first Webpage</h4>
				<ul>
					<li>
						- Precursor this site
					</li>
					<li>	
						- uploaded to npage, now discontinued
					</li>
					<li>
						- no more copy
					</li>
					<li>
						- add fake screenshot
					</li>
					<li>
						had a quizz, some visual basic 6 games and cs 1.6 maps
					</li>
					<li>
						precourse to this site
					</li>
				</ul>
		</div>
	)

}


