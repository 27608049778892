import {Link} from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'


export default function CSB(){
	const {t, i18n} = useTranslation("csb");
	return (
		<div>
			<h4>{t('title')}</h4>
			<br />
			<p>
				{t('c1')}
			</p>
			<p>
				<Trans i18nKey={'c2'} t={t}>
				x
					<Link to="/projects">
					y
					</Link>
				z
				</Trans>
			</p>
			<p>
				{t('c3')}
			</p>
			<p>
				{t('c4')}
			</p>
		</div>
	)

}


