import {useState,useEffect,useRef} from 'react';
import useWindowSize from '../common/useWindowSize.js';
import Scene from './scene.js'


export default function Space({}) {
	const container = useRef();
	const [scene, setScene] = useState(null);



	const [width, height] = useWindowSize();
	useEffect(() => {

		let scene_=null
		if (container.current) {
			scene_ = new Scene(container.current);
			setScene(scene_);
		}
		return () => {
			scene_?.destroy();

		}
	}, [width,height]);

	//create full screen canvas
	return (
		<div ref={container} className="p-0 m-0 position-absolute top-0 start-0 w-100 h-100"  style={{zIndex:"-2"}}>
		</div>
	)
}
// <!--<canvas className="p-0 m-0 w-100 h-100" id="space"></canvas>
