import * as Bootstrap from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import {SiteContext} from '../space/SiteContext.js';
import {useContext} from 'react'
import {useState} from 'react'
import {useTranslation} from 'react-i18next';



function NavbarLink({text,to,onClick}) {
	return(
		<div className="ps-4 flex-grow-1 text-center">
			<NavLink to={to} className="navbar-link" onClick={onClick}>
				{text}
			</NavLink>
		</div>
	)

}
export default function Navbar({}) {
	const {setSiteContext,siteContext} = useContext(SiteContext);
	const [expanded, setExpanded] = useState(false);
	const close=()=>setExpanded(false)
	const {t,i18n} = useTranslation("ui");


	const selectedLanguage = i18n.language.startsWith("de")?"de":"en";

	
	return (
		<>
			<Bootstrap.Navbar bg="none" expand="lg"  expanded={expanded}>
				<Bootstrap.Container className="navbar-container mt-3 mb-5 d-flex flex-row">
					<Bootstrap.Navbar.Toggle aria-controls="basic-navbar-nav"   onClick={() => setExpanded(expanded ? false : "expanded")}>
						<i className="navbar-link bi bi-list"></i>
					</Bootstrap.Navbar.Toggle>
					<i role="button" className={"navbar-link bi bi-stars "+(siteContext.spaceEnabled?"glowing":" text-secondary")} onClick={()=>setSiteContext(s=>({...s,spaceEnabled:!s.spaceEnabled}))}></i>
					<div onClick={()=>i18n.changeLanguage(selectedLanguage==="de"?"en":"de")} className="user-select-none ms-3 d-flex flex-column" role="button" style={{fontSize:"9pt"}}>
						<div className={(selectedLanguage==="en"?"text-white":"text-secondary")+" mb-0"}>
							<b>EN</b>
						</div>
						<div className={(selectedLanguage==="de"?"text-white":"text-secondary")+" mb-0"}>
							<b>DE</b>
						</div>
					</div>
					<Bootstrap.Navbar.Collapse id="basic-navbar-nav">
						<Bootstrap.Nav className="me-auto">
							<NavbarLink to="/aboutme" text={t("aboutme")} onClick={close}/>
							<NavbarLink to="/projects" text={t("projects")} onClick={close}/>
							<NavbarLink to="/workhistory" text={t("career")} onClick={close}/>
							<NavbarLink to="/education" text={t("education")} onClick={close}/>
						</Bootstrap.Nav>
					</Bootstrap.Navbar.Collapse>
				</Bootstrap.Container>
			</Bootstrap.Navbar>

		</>
	)



}
/*
				<i role="button" className={"order-last order-md-first navbar-link bi bi-stars "+(siteContext.spaceEnabled?"":" text-secondary")} onClick={()=>setSiteContext(s=>({...s,spaceEnabled:!s.spaceEnabled}))}></i>
				<NavbarLink to="/projects" text="Projects"/>
				<NavbarLink to="/aboutme" text="About Me"/>
				<NavbarLink to="/workhistory" text="Work History"/>
				<NavbarLink to="/education" text="Education"/>
				*/


