import { Link } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'


export default function Kreis(){
	const {t, i18n} = useTranslation("kreis");

	return (
		<div>
			<h4>{t('title')}</h4>
			<br/>
			<p>
				{t('c1')}
			</p>
			<p>
				<Trans i18nKey="c2" t={t}>
					x
					<span>
						y
					</span>
					z
				</Trans>
			</p>

		</div>
	)

}


