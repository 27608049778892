import GenericContainer from "./GenericContainer.js"
import ListItem from "./ListItem.js"
import AnimatedList from "./AnimatedList.js"
import {Link} from 'react-router-dom'
import LBImage from './LBImage.js'
import { Trans, useTranslation } from 'react-i18next'



export default function WorkHistory() {
	const {t, i18n} = useTranslation("career");


	const templates = [
		{
			company:t('trikora.title'),
			time:"2023-2024",
			title:t('trikora.role'),
			city:"Aarwangen",
			description:t('trikora.description'),
			image:"images/adiacom.png",
			link:"/workhistory/trikora",
			duration:2

		},
		{
			company:t('adiacom.title'),
			time:"2022-2023",
			title:t('adiacom.role'),
			city:"Basel",
			description:t('adiacom.description'),
			image:"images/unblu.png",
			link:"/workhistory/adiacom",
			duration:3

		},
		{
			company:t('csb.title'),
			time:"2013-2015",
			title:t('csb.role'),
			city:"Kestenholz",
			description:t('csb.description'),
			image:"images/csb.png",
			link:"/workhistory/csb",
			duration:2

		},
		{
			company:t('allware.title'),
			time:"2011",
			title:t('allware.role'),
			city:"Trimbach",
			description:t('allware.description'),
			image:"images/allware.png",
			link:"/workhistory/allware",
			duration:1
		},
	]

	return (
		<GenericContainer className="overflow-auto flex-grow-1 d-flex flex-column mb-4" style={{gap:"10pt"}}>
			<div className="m-4">
				<h1>{t('title')}</h1>
			</div>
			<AnimatedList numElements={templates.length} children={(i)=>(
				<ListItem className="d-flex flex-row m-3" img={templates[i].image}>
					<div>
						<h5>
							<Link  to={templates[i].link}>{templates[i].company}</Link>
						</h5>
					</div>
					<p>
						<b>{templates[i].title}</b> -  
						<span>{templates[i].time}</span> - 
						<b>{templates[i].city}</b> - 
						<span> {templates[i].duration} {templates[i].duration===1?t('ui:minute'):t('ui:minutes')} {t('ui:readingtime')}</span>
					</p>
					<p>{templates[i].description}</p>
					<p><Link  className="text-white text-decoration-none" to={templates[i].link}><b>{t('ui:readmore')}</b></Link></p>
				</ListItem>
			)} />

		</GenericContainer>
	)
}

