import { Link } from 'react-router-dom'
import {useTranslation,Trans} from 'react-i18next'


export default function FHNW(){

	const {t} = useTranslation("fhnw");

	return (
		<div>
			<h4>{t('title')}</h4>
			<br />
			<p>
				{t('c1')}
			</p>
			<p>
				<Trans i18nKey="c2" t={t}>
					x
					<Link to="/projects">y</Link>
					z	
				</Trans>
			</p>
			<p>
				{t('c3')}
			</p>

			<br />
			<h5>{t('compilerTitle')}</h5>
			<br />

			<p>
				<Trans i18nKey="compilerC1" t={t}>
					x<Link to="/projects/compiler">y</Link> z
				</Trans>
			</p>
			<p>
				{t('compilerC2')}
			</p>

			<br />
			<h5>{t('distTitle')}</h5>
			<br />

			<p>
				{t('distC1')}
			</p>

			<br />
			<h5>{t('cryptTitle')}</h5>
			<br />
			<p>
				{t('cryptC1')}
			</p>


			<br />
			<h5>{t('imgTitle')}</h5>
			<br />
			<p>
				{t('imgC1')}
			</p>
			<p>
				{t('imgC2')}
			</p>
			<p>
				{t('imgC3')}
			</p>
			<br />
			<h5>{t('blckTitle')}</h5>
			<br />
			<p>
				{t('blckC1')}
			</p>
			<p>
				<Trans i18nKey="blckC2" t={t}>
					x<Link to="/projects/lotto">y</Link>z
				</Trans>
			</p>
			<br />
			<h5>{t('cgrTitle')}</h5>
			<br />
			<p>
				<Trans i18nKey="cgrC1" t={t}>
					x<Link to="/projects/HLMSDS">y</Link>z
				</Trans>
			</p>
			<p>
				{t('cgrC2')}
			</p>
			<p>
				<Trans i18nKey="cgrC3" t={t}>
				x<Link to="/projects/raytracer"> y</Link>z
				</Trans>
			</p>
			<br />
			<h5>{t('patTitle')}</h5>
			<br />
			<p>
				{t('patC1')}
			</p>

			<br />
			<h5>{t("machTitle")}</h5>
			<p>
				{t('machC1')}
			</p>
			<p>
				{t('machC2')}
			</p>
			<p>
				{t('machC3')}
			</p>

			<br />

		</div>
	)

}


