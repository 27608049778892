import GenericContainer from "./GenericContainer.js"

import { Trans, useTranslation } from 'react-i18next'
import {Link} from 'react-router-dom'
import LBImage from './LBImage.js'
import QuickForm from '../common/QuickForm.jsx'
import { useState} from "react"


export default function AboutMe({}) {
	const {t, i18n} = useTranslation("aboutme");

	const [show, setShow] = useState(false);

	return (
		<GenericContainer className="overflow-auto flex-grow-1 d-flex flex-column mb-4" style={{gap:"10pt"}}>
			<QuickForm show={show} onHide={()=>setShow(false)}/>
			<div className="row">

				{/*
				<div className="col-md-6 order-md-last ">
					<LBImage src="/images/pictures.jpg" alt="me" className="ms-auto p-4"/>
				</div>
				*/
				}
				<div className="col-md-12">	
					<h3>{t('btitle')}</h3>
					<p>
						{t('b1')}
					</p>

					<p>
						{t('b2')}
					</p>
					<ul>
						<li>
							<Trans i18nKey="b3" t={t}>
								x<Link to="/projects">y</Link>z
							</Trans>
						</li>
						<li>
							<Trans i18nKey="b4" t={t}>
								x<Link to="/workhistory">y</Link>z
							</Trans>
						</li>
						<li>
							<Trans i18nKey="b5" t={t}>
								x<Link to="/education">y</Link>z
							</Trans>
						</li>
					</ul>
					<p>

						<Trans i18nKey="b6" t={t}>
							x<Link onClick={()=>setShow(true)}>d</Link> y
						</Trans>
						<img src="/images/email.png" alt="email"  className="m-1"/>. 
					</p>
					<p>
						<Trans i18nKey="b6.1" t={t}>
							z<Link to="https://github.com/white-waluigi/">x</Link> x<Link to="https://www.linkedin.com/in/marvin-wyss-7549a021b/">d</Link>a 
						</Trans>
					</p>
					<p>
						{t('b7')}
					</p>






				</div>

			</div>
		</GenericContainer>
	)
}
/*
							<div className="col-md-12">
								<p>
									{t('c1')}
								</p>
								<p>
									{t('c1.1')}
									<img src="/images/email.png" alt="email"  className="ms-1"/>
								</p>
								<p>
									<Trans i18nKey="c1.2" t={t}>
										u
										<Link to="https://github.com/white-waluigi/">v</Link>
										w
										<Link to="https://www.linkedin.com/in/marvin-wyss-7549a021b/">x</Link>
										y	
									</Trans>
								</p>
								<p>
									{t('c2')}
								</p>
								<p>
									{t('c2.2')}
								</p>
							</div>
							*/
