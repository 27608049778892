import GenericContainer from './GenericContainer.js'
import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

export default function Article({back,children}) {

	const {t} = useTranslation('ui')

	return(
		<GenericContainer className="overflow-auto flex-grow-1 d-flex flex-column mb-4" style={{gap:"10pt"}}>
			<h5><Link to={back}> <i className="bi bi-arrow-left"></i> {t('back')}</Link></h5>
			{children}

		</GenericContainer>
	)

}
