import CaptionedImage from '../CaptionedImage.js'
import CaptionedVideo from '../CaptionedVideo.js'

import { Trans, useTranslation } from 'react-i18next'


export default function Icarus(){
	const {t, i18n} = useTranslation("icarus");

	
	
	
	
	
	
	
	
	return (
		<div>
			<h4>{t("title")}</h4>
			<br />
			<p>{t('c1')}</p>
			<p>{t('c2')}</p>
			<p>{t('c3')}</p>
			<p>{t('c4')}</p>
			<p>{t('c5')}</p>
			<CaptionedImage src="/images/front.jpg" caption={t('cap1')} />
			<CaptionedVideo src="/images/teapot.mp4" caption={t('cap2')} />
			<p>{t('c6')}</p>
			<CaptionedImage src="/images/back.jpg" caption={t('cap3')} />
			<p>{t('c7')}</p>
			<CaptionedImage src="/images/shoulder.jpg" caption={t('cap4')} />
			<p>{t('c8')}</p>
				<CaptionedImage src="/images/inprogress.jpg" caption={t('cap5')} />
			<CaptionedVideo src="/images/wave.mp4" caption={t('cap6')} />
			<p>{t('c9')}</p>
			<CaptionedImage src="/images/led.jpg" caption={t('cap7')} />
			<CaptionedVideo src="/images/tracking.mp4" caption={t('cap8')} />
			<p>{t('c10')}</p>
			<p>{t('c11')}</p>
			<p>{t('c12')}</p>
			<CaptionedVideo src="/images/stretch.mp4" caption={t('cap9')} />
			<CaptionedImage src="/images/jacket.jpg" caption={t('cap10')} />
			<CaptionedImage src="/images/helmet.jpg" caption={t('cap11')} />
			<CaptionedImage src="/images/icarus.jpg" caption={t('cap12')} />
		</div>
	)

}

