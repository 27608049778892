import { useTranslation } from 'react-i18next'

export default function NLP(){

	const {t} = useTranslation(["coursera"])

	return (
		<div>
			<h4>{t('title')}</h4>
			<br />
			<p>
				{t('intro')}
			</p>
			<h5 className="py-2">{t('mltitle')}</h5>
			<p>
				{t('ml1')}
			</p>
			<h5 className="py-2">{t('dltitle')}</h5>
			<p>
				{t('dl1')}
			</p>
			<h5 className="py-2">{t('nlptitle')}</h5>
			<p>
				{t('nlp1')}
			</p>
			<h5 className="py-2">{t('medtitle')}</h5>
			<p>
				{t('med1')}
			</p>
			<h5 className="py-2">{t('mathtitle')}</h5>
			<p>
				{t('math1')}
			</p>
			<h5 className="py-2">{t('llmtitle')}</h5>
			<p>
				{t('llm1')}
			</p>
		</div>
	)

}


